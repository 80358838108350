<template>
  <a class="survey-btn" :href="link">
    <div class="survey-head">
      <font-awesome-icon :icon="['far', 'pen']" style="padding-right:15px" />
      <h3>{{ data.SurveyName }}</h3>
    </div>
    <div class="survey-body">
      <p>{{ data.SurveyDescription }}</p>
    </div>
    <p class="add-survey-btn"> {{ $t.getTranslation('LBL_ENTER_SURVEY') }}</p>
  </a>
</template>

<script>
import { defineComponent, ref, inject, onMounted } from 'vue'
import { useStore } from "@/store";

export default defineComponent({
  name: 'SurveyListComponent',
  components: {},
  props: {
    data: Object
  },
  setup(props) {
    const store = useStore();
   
    const link = `/survey/view/?key=${props.data.SurveyKey}`
    return {
      link
    }
  }
})
</script>

<style scoped>
.survey-btn {
    background-color: #f2f6f9;
    display: flex;
    padding: 20px 25px;
    border-radius: 10px 50px 10px 10px;
    justify-content: center;
    margin: 20px 20px 0 20px;
    cursor: pointer;
    flex-direction: column;
    align-items: flex-end;
    min-height: 150px;
}
.survey-head {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 25px;
  padding-bottom: 10px;
  width: 100%;
  color: #0c4d8f;
  border-bottom: 1px solid #ccc;
}
.survey-body {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
  color: black;
}
/* .survey-title{
    width: 200px;
    text-align: left;
} */
/* .survey-btn{
    font-size: 20px;
    padding: 20px;
} */
.add-survey-btn{
    background-color: #0c4d8f;
    margin-top: 10px;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: #fff;
    padding: 5px 15px;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
}
</style>
